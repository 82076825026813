import * as React from "react"
import { Box, BoxProps } from "theme-ui"

import IconCheckmarkThin from "../../../assets/svg/v2/checkmark-thin.svg"
import IconCheckmark from "../../../assets/svg/v2/checkmark.svg"
import IconChevronDown from "../../../assets/svg/v2/chevron-down.svg"
import IconCopy from "../../../assets/svg/v2/copy.svg"
import IconEye from "../../../assets/svg/v2/eye.svg"
import IconGlobe from "../../../assets/svg/v2/globe.svg"
import IconHeartEmpty from "../../../assets/svg/v2/heart-empty.svg"
import IconHeartFilled from "../../../assets/svg/v2/heart-filled.svg"
import IconInfo from "../../../assets/svg/v2/info.svg"
import IconMessageSquare from "../../../assets/svg/v2/message-square.svg"
import IconPlus from "../../../assets/svg/v2/plus.svg"
import IconSearch from "../../../assets/svg/v2/search.svg"
import IconShoppingBag from "../../../assets/svg/v2/shopping-bag.svg"
import IconSliders from "../../../assets/svg/v2/sliders.svg"
import IconSwitch from "../../../assets/svg/v2/switch.svg"
import IconUser from "../../../assets/svg/v2/user.svg"
import IconX from "../../../assets/svg/v2/x.svg"
import Theme from "src/Theme"

export interface IconProps extends BoxProps {
  name:
    | "checkmark-thin"
    | "checkmark"
    | "chevron-down"
    | "copy"
    | "eye"
    | "globe"
    | "heart-empty"
    | "heart-filled"
    | "info"
    | "message-square"
    | "plus"
    | "search"
    | "shopping-bag"
    | "sliders"
    | "switch"
    | "user"
    | "x"
  size?: keyof (typeof Theme)["sizes"] | `${keyof (typeof Theme)["sizes"]}`
}

export const Icon: React.FC<IconProps> = ({
  name,
  size = 6,
  sx,
  ...boxProps
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        svg: {
          display: "block",
          width: size,
          height: size,
        },
        ...sx,
      }}
      {...boxProps}
    >
      <>
        {name === "checkmark-thin" && <IconCheckmarkThin />}
        {name === "checkmark" && <IconCheckmark />}
        {name === "chevron-down" && <IconChevronDown />}
        {name === "copy" && <IconCopy />}
        {name === "eye" && <IconEye />}
        {name === "globe" && <IconGlobe />}
        {name === "heart-empty" && <IconHeartEmpty />}
        {name === "heart-filled" && <IconHeartFilled />}
        {name === "info" && <IconInfo />}
        {name === "message-square" && <IconMessageSquare />}
        {name === "plus" && <IconPlus />}
        {name === "search" && <IconSearch />}
        {name === "shopping-bag" && <IconShoppingBag />}
        {name === "sliders" && <IconSliders />}
        {name === "switch" && <IconSwitch />}
        {name === "user" && <IconUser />}
        {name === "x" && <IconX />}
      </>
    </Box>
  )
}
