import * as React from "react"

// External packages
import GatsbyLink from "gatsby-link"

// Components
import { UiLink, UiLinkProps } from "./ui/Link"

export type LinkProps<
  To extends string | undefined = undefined,
  Href extends string | undefined = undefined
> = {
  to?: To
  href?: Href
} & Omit<
  To extends undefined
    ? Href extends undefined
      ? UiLinkProps<"button">
      : UiLinkProps<"a">
    : UiLinkProps<typeof GatsbyLink<{}>>,
  "as"
>

export const Link = <
  To extends string | undefined = undefined,
  Href extends string | undefined = undefined
>(
  props: LinkProps<To, Href>
) => {
  if (props.to && /^\/(?!\/)/.test(props.to)) {
    return <UiLink {...props} as={GatsbyLink} />
  }

  if (!props.to && !props.href) {
    return <UiLink {...props} as="button" />
  }

  if (props.href) {
    return <UiLink {...props} as="a" href={props.href} />
  }

  return <UiLink {...props} as="a" href={props.to} />
}
